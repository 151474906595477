import React, { useState } from "react";
import { CustomButton } from "../index";
import "./Modal.css";

const Modal = ({ showModal, setShowModal }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const webhookURL = process.env.REACT_APP_MAKE_WEBHOOK_BROCHURE_URL;

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/awa-tour-brochure-tonga.pdf"; // Path to the brochure
    link.download = "awa-tour-brochure-tonga.pdf"; // Filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const submit = (e) => {
    e.preventDefault();

    const sanitisedFirstName = firstName.trim();
    const sanitisedLastName = lastName.trim();
    const sanitisedEmail = email.trim();

    if (!sanitisedFirstName || !sanitisedLastName || !sanitisedEmail) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    if (!isValidEmail(sanitisedEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const currentDate = new Date().toISOString().slice(0, 10);

    setErrorMessage("");

    fetch(webhookURL, {
      method: "POST",
      body: JSON.stringify({
        firstName: sanitisedFirstName,
        lastName: sanitisedLastName,
        email: sanitisedEmail,
        date: currentDate,
        isSubscribed,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send the request.");
        }
        handleDownload(); // Trigger the download after successful form submission
        localStorage.setItem("downloadedTourGuide", "true");
        setShowModal(false); // Close the modal
        setFirstName("");
        setLastName("");
        setEmail("");
      })
      .catch(() => {
        setErrorMessage("There was an error, please try again.");
      });
  };

  if (!showModal) return null;

  return (
    <div className='modal'>
      <div
        className='modal-content'
        role='dialog'
        aria-labelledby='modal-title'
      >
        <button
          className='modal-close'
          onClick={() => setShowModal(false)}
          aria-label='Close Modal'
        >
          &times;
        </button>
        <h3 id='modal-title'>Your Information</h3>
        <p>Enter your details to receive our brochure:</p>
        <form onSubmit={submit}>
          <input
            type='text'
            placeholder='First name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value.trimStart())}
            required
            aria-label='First Name'
          />
          <input
            type='text'
            placeholder='Last name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value.trimStart())}
            required
            aria-label='Last Name'
          />
          <input
            type='email'
            placeholder='Email Address'
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            required
            aria-label='Email Address'
          />{" "}
          <label className='label-container'>
            <input
              type='checkbox'
              checked={isSubscribed}
              onChange={(e) => setIsSubscribed(e.target.checked)}
            />
            <span>Subscribe to our news & updates.</span>
          </label>
          {errorMessage && (
            <p className='error-message' aria-live='polite'>
              {errorMessage}
            </p>
          )}
          <CustomButton
            className='hero-btn'
            type='submit'
            text='Download Brochure'
          />
        </form>
      </div>
    </div>
  );
};

export default Modal;
