import React, { useEffect, useState } from "react";
import NewsletterPopup from "./NewsletterPopup";

const PopupManager = () => {
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);

  useEffect(() => {
    const popupShown = localStorage.getItem("popupShown");

    if (!popupShown) {
      const timer = setTimeout(() => {
        setShowNewsletterPopup(true); // Corrected to set showNewsletterPopup
      }, 10000); // Show the popup after 10 seconds

      return () => clearTimeout(timer); // Cleanup on component unmount
    }
  }, []);

  const closeNewsletterPopup = () => {
    setShowNewsletterPopup(false);
    localStorage.setItem("popupShown", "true"); // Mark the popup as shown in localStorage
  };

  const handleSubscribe = (event) => {
    event.preventDefault();
    alert("Thank you for subscribing!");
    closeNewsletterPopup(); // Close the popup after subscribing
  };

  return (
    <>
      {showNewsletterPopup && (
        <NewsletterPopup
          onClose={closeNewsletterPopup}
          onSubscribe={handleSubscribe}
        />
      )}
    </>
  );
};

export default PopupManager;
