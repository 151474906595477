export const faqs = [
  {
    question: "What should I expect during my stay?",
    answer: `
    <p>Uoleva Island is a secluded paradise, offering a true escape from the hustle and bustle.</p>
    <p>With no shops, roads, or nightlife, this is the perfect place to disconnect and unwind. All meals are either grown on-site or brought in via ferry from Nuku’alofa once a week.</p>
    <p>The fales we stay in are some of the most beautiful and comfortable in Tonga, allowing you to fully immerse in the island's natural beauty.</p>
    <p>While there is no pool or cocktail bar, alcohol is available for purchase. You can enjoy a relaxing drink while lounging in a hammock or on a beanbag under the palm trees. Uoleva is a unique destination for those seeking peace and tranquility in an unspoiled natural setting.</p>
    `,
  },
  {
    question: "How do we travel from Ha'apai Airport to the resort?",
    answer: `
    <p>Upon arrival at Ha'apai Airport, you’ll be transferred by car to the wharf, followed by a scenic 30-minute boat trip to Uoleva Island.</p>
    <p>Be prepared to get your feet wet when you disembark, so we recommend wearing clothes that can handle a bit of water.</p>
    `,
  },
  {
    question: "What meals are provided during the trip?",
    answer: `
    <p>We include all main meals during your stay at the eco-resort. You’ll just need to purchase your own snacks and drinks, which can be done on-site.</p>
    `,
  },
  {
    question: "How do I pay for snacks and drinks?",
    answer: `
    <p>Payments for any additional snacks and drinks can be made at the end of your stay, using a credit card.</p>
    `,
  },
  {
    question: "What sort of weather should I expect?",
    answer: `
    <p>Our adventure takes place during Tonga's winter. The temperature is typically warm, ranging from 24°C to 28°C during the day, though it’s best to be prepared for cooler conditions as well, especially in the evenings.</p>
    `,
  },
  {
    question: "How long will we be out on the boat?",
    answer: `
    <p>Each boat trip lasts around 5 hours. The boat departs directly from the resort unless the tides are too low, in which case we meet a short 10-minute walk down the beach.</p>
    `,
  },
  {
    question: "What are the chances of swimming with the whales?",
    answer: `
    <p>While we can never guarantee a sighting, we do everything we can to ensure a high chance of success. Our trips run over five days, giving us the best opportunity to encounter the whales.</p>
    `,
  },
  {
    question: "Is Tonga a windy destination?",
    answer: `
    <p>While Tonga is popular for kite surfing, the trade winds come from the southeast, and the resort is located on the sheltered side of the island, providing a calm, peaceful setting — protected from the strong winds.</p>
    `,
  },
  {
    question: "What do I need to bring with me?",
    answer: `
    <p>Ahead of your trip, we’ll send you a detailed packing list and trip itinerary, but here are some essentials to bring:</p>
    <ul>
    <li>Beach towels (bath towels are provided)</li>
    <li>Sunscreen</li>
    <li>Insect repellent</li>
    <li>Medications</li>
    <li>Headlamps/torches</li>
    <li>Rash vests</li>
    <li>Hats</li>
    <li>Reef shoes</li>
    <li>3/2mm shorty wetsuit (consider a warmer one if you tend to get cold)</li>
    </ul>
    <p>Mosquito nets are also provided in the fales.</p>
    `,
  },
  {
    question: "Why is this resort considered eco-friendly?",
    answer: `
    <p>Fanifo Loa is committed to sustainability and environmental consciousness. The resort uses solar power, and all buildings are constructed using timber to minimise environmental impact.</p>
    <p>Composting toilets replace traditional septic systems, and efforts are made to reduce waste, conserve water, and engage with the local community through sustainable practices.</p>
    `,
  },
  {
    question: "Can I scuba dive with the whales during this trip?",
    answer: `
    <p>This tour is focused on snorkelling with the whales. No scuba diving experience is required, so you can enjoy the encounter from the surface.</p>
    `,
  },
  {
    question: "What happens if we don’t spot any whales?",
    answer: `
    <p>While sightings are never guaranteed, our experienced guides maximise the chances of an encounter. If you don’t get to swim with whales on your first day, we’ll continue to search throughout the tour.</p>
    `,
  },
  {
    question: "Are there any age restrictions for the tour?",
    answer: `
    <p>This tour is designed for adults and children over the age of 16 who are comfortable swimming in the ocean and snorkelling. If you’re unsure about whether it’s suitable for you or your child, please contact us, and we’ll be happy to discuss the details.</p>
    `,
  },

  {
    question: "Will there be internet available?",
    answer: `
    <p>Wireless internet may be available for a small fee, but please note that due to the remote location, access can be intermittent or unavailable at times. It’s best to come prepared to disconnect and fully embrace the tranquility of the island.</p>
    `,
  },
  {
    question: "What are the visa requirements?",
    answer: `
    <p>Visitors from most countries may enter Tonga without a visa for a period no longer than 31 days. Travellers from some countries do require a visa. We recommend checking with the Tongan Immigration Department directly. It is also a good idea to make sure you have more than 6 months validity left on your passport or you may be denied entry.</p>`,
  },
];

export const landingPageFaqs = [
  {
    question: "What should I expect during my stay?",
    answer: `
      <p>Uoleva Island is a secluded paradise, with no shops, roads, or nightlife—just the perfect place to disconnect and unwind. All meals are prepared with fresh ingredients either grown on-site or brought in via ferry once a week. The food is delicious and full of flavour, far beyond what you'd expect in such a remote setting.</p>
      <p>The fales we stay in are some of the most beautiful and comfortable in Tonga, allowing you to fully immerse yourself in the island's natural beauty.</p>
      <p>While there’s no pool or cocktail bar, alcohol is available for purchase. You can enjoy a relaxing drink while lounging in a hammock or on a beanbag under the palm trees after a day of incredible whale adventures!</p>
    `,
  },
  {
    question: "What meals are provided during the trip?",
    answer: `
      <p>All main meals are included during your stay at the eco-resort. Our hosts can cater for vegetarians, and other dietary requirements may incur an additional charge due to the remoteness of the location. Snacks and drinks can be purchased on-site and settled by credit card before you leave.</p>
    `,
  },
  {
    question: "What's included?",
    answer: `
      <p>This is a fully guided tour by our knowledgeable Awesome Whale Adventures team.</p>
      <ul>
      <li>5 Days of Whale Swimming excursions</li>
      <li>Experienced, knowledgeable whale guides</li>
      <li>7 Nights in Beachfront Fales on Uoleva</li>
      <li>All Main Meals (Daily continental breakfast, packed lunch, and a 2-course dinner)</li>
      <li>Return inter-island flights from Tongatapu to Ha'apai</li>
      <li>Round trip transfers from Ha'apai airport to our accommodation</li>
      <li>Escorted tour guide on-hand to oversee all logistics</li>
      <li>Complimentary access to kayaks and stand-up paddleboards</li>
      <li>Yoga deck and mats available for self-led practice</li>
      <li>Off-grid introduction tour available on request</li>
  </ul>
  
    `,
  },
  {
    question: "What’s not included?",
    answer: `<ul>
    <li>International flights (not included)</li>
    <li>Travel insurance (required)</li>
    <li>Snorkelling equipment (required)</li>
    <li>Wetsuits (optional)</li>
    <li>Excess baggage charges</li>
    <li>Visa and passport fees</li>
    <li>Optional activities and personal expenses (e.g., Wi-Fi charges, alcohol, and other discretionary costs)</li>
  
      </ul>  
      <p>Please note that wildlife sightings and weather cannot be guaranteed. For help booking your flights or if you have other enquiries, please get in touch and we can assist.</p>
    `,
  },
  {
    question: "How much does the tour cost?",
    answer: `
    <p>The cost of the tour is $6,845 AUD (Australian dollars, per person twin share). This includes all accommodation, meals, inter-island flights, whale swimming days, transfers, and guided support from our Awesome Whale Adventures team.</p> <p>For solo travellers, a solo rate of $7,995 AUD is available.</p> <p>A 30% deposit is required to secure your booking, with the remaining balance due 120 days before departure. Payment plans can also be arranged—just reach out to us to discuss your options.</p>
    `,
  },
  {
    question: "Do I need to know how to scuba dive?",
    answer: `
      <p>No scuba diving experience is required! Our tour is focused on snorkelling with the whales, where you’ll experience these majestic creatures from the surface. While no diving skills are needed, it’s important to be a strong swimmer.</p>
    `,
  },
  {
    question: "Do I need to be a strong swimmer?",
    answer: `
      <p>Yes, to get the most out of the experience, you’ll need to be a strong swimmer as we will be in open water. If you’re not confident in the water, you can stay on the boat and still enjoy whale watching from the vessel.</p>
      <p>Your safety and comfort are our top priorities, and we want everyone to have an unforgettable time.</p>
    `,
  },
  {
    question: "How equipment do I need?",
    answer: `
    <p>
    We recommend investing in a good quality mask, snorkel, and
    fins (ideally free-diving fins for extra propulsion). For the best
    fit, visit your local dive shop or get in touch with our team for
    personalised recommendations. It’s also a good idea to get familiar
    with your gear before your trip—practice wearing it to ensure
    comfort and proper function, as this can make a significant
    difference in your experience. For the wetsuit, we suggest opting
    for a 1.5 - 3mm full-body suit, depending on how sensitive you are
    to the cold. While the water temperature is generally comfortable,
    you'll likely appreciate the extra warmth when you’re between swims
    or when staying in the water for long periods, and it also helps
    with sun protection.
  </p>
    `,
  },
  {
    question: "How likely is it that I'll swim with whales on this tour?",
    answer: `
    <p>
    Having an amazing whale swim experience depends on ocean conditions, their behaviour, and ensuring both their safety and yours and with this in mind, we've designed our tour to give you the best possible chance to swim with these majestic creatures as many times as possible. </p><p>With five dedicated whale swim days, you’ll have ample opportunities to see them up close in their natural habitat. Combined with our experienced team and thoughtful planning, swimming with them is highly likely and may even be possible multiple times on each day. </p><p>As with all wildlife encounters, we cannot offer guarantees, but every effort is made to create a truly unforgettable experience.
 </p>
 
  
    `,
  },
  {
    question: "Is it ethical to swim with whales?",
    answer: `
      <p>Ensuring ethical and responsible interactions with whales is a key part of our tours. We strictly follow responsible guidelines to protect the well-being of the whales. These guidelines ensure the animals are approached at safe distances and never feel stressed or threatened. When whales are comfortable, they often display their natural curiosity, choosing to interact with swimmers on their own terms.</p>
      <p>Our team prioritises respect for the whales and their environment, contributing to conservation efforts and raising awareness about these incredible creatures.</p>
    `,
  },
  {
    question: "Can I speak to someone about this tour?",
    answer: `
      <p>We’d love to speak with you about this trip and answer any questions you have! Feel free to call, text, or WhatsApp our friendly team on <strong>+61 485 933 782</strong>. We take great pride in our personal service, ensuring we’re with you every step of the way, from planning your adventure to your journey back home.</p>
    `,
  },
];
