import React from "react";
import { Hero, SEO, WhyBookWithUs } from "../components";

const Home = () => {
  return (
    <>
      <SEO
        title='Home'
        description='Explore unforgettable and ethical whale multi-day expeditions with Awesome Whale Adventures.'
        keywords='whale expeditions, whale holidays, swim with whales, ethical whale tours, whale swimming, whale watching'
      />
      <Hero />
      <WhyBookWithUs />
    </>
  );
};

export default Home;
