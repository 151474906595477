import React from "react";
import {
  SEO,
  HeroSection,
  KeyFeaturesSection,
  AboutExperienceSection,
  TestimonialSection,
  FaqSection,
  FinalCTASection,
  Gallery,
} from "../components";
import { useResponsive } from "../hooks";

const LandingPageMain = () => {
  const propertyUrls = [
    "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/sunset",
    "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/uoleva-aerial",
    "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/island-breakfast",
  ];

  const whaleUrl = [
    "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/humpback-whales",
  ];
  const { isMobile } = useResponsive();

  return (
    <>
      <SEO
        title='Tonga Swim With Whales | Swim with Humpback Whales'
        description='Swim with humpback whales in Tonga, guided by experts in an ethical, eco-friendly, small-group adventure.'
        keywords='Tonga Swim With Whales, tonga swimming with whales, swim with humpback whales, swimming with whales'
        image='https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/humpback-calf-tonga'
      />

      <div className='pages-container landing-page'>
        <HeroSection />
        <KeyFeaturesSection />
        <AboutExperienceSection />
        <Gallery imageUrls={propertyUrls} layout='grid' />
        <TestimonialSection />
        <FaqSection />
        {isMobile && <Gallery imageUrls={whaleUrl} layout='grid' />}
        <FinalCTASection />
      </div>
    </>
  );
};

export default LandingPageMain;
