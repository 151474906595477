import React, { useState } from "react";
import { CustomButton } from "../index";

const NewsletterForm = ({ onSuccess, errorMessage }) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const subscribeWebhookURL = process.env.REACT_APP_MAKE_SUBSCRIBE_URL;

    fetch(subscribeWebhookURL, {
      method: "POST",
      body: JSON.stringify({ firstName, email }), // Send firstName, lastName, and email
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to subscribe. Please try again.");
        }
        onSuccess(); // Call onSuccess when successful

        // Store subscription status in localStorage
        localStorage.setItem("subscribedToNewsletter", "true");

        setFirstName(""); // Clear the fields
        setEmail("");
      })
      .catch((error) => {
        console.error("Subscription failed:", error);
        // Set error message in parent component
        if (errorMessage) {
          errorMessage("Failed to subscribe. Please try again."); // Pass error
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type='text'
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder='First name'
        required
      />
      <input
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email address'
        required
      />
      <CustomButton
        type='submit'
        text={isLoading ? "Subscribing..." : "Subscribe"}
        disabled={isLoading}
        alternateColor={true}
      />
      {errorMessage && <p className='error'>{errorMessage}</p>}{" "}
      {/* Display errors */}
    </form>
  );
};

export default NewsletterForm;
