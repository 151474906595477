import React, { useState } from "react";
import NewsletterForm from "./NewsletterForm";
import { useResponsive } from "../../hooks";

const NewsletterFooter = () => {
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Keep errorMessage

  const { isMobile } = useResponsive();

  const handleSuccess = () => {
    setIsSent(true); // Subscription successful
    setErrorMessage(""); // Clear any previous errors
  };

  const handleError = (error) => {
    setErrorMessage(error); // Set the error message
  };

  return (
    <div className='newsletter-container'>
      {isSent ? (
        <>
          <h2>Thank you for subscribing!</h2>
          <p>
            You're now part of our community! Get ready to receive the latest
            updates, exclusive offers, and exciting Awesome Whale Adventure news
            straight to your inbox. We can’t wait to share our adventures with
            you!
          </p>
        </>
      ) : (
        <>
          {isMobile ? (
            <h3>Join Our Exclusive Community</h3>
          ) : (
            <h3>Join Our Exclusive Whale Lovers Community</h3>
          )}
          {isMobile ? (
            <p>Subscribe to our newsletter now</p>
          ) : (
            <p>
              Subscribe now to receive exclusive deals, early access to tours,
              and updates on incredible whale adventures. Be the first to know!
            </p>
          )}
          <NewsletterForm
            onSuccess={handleSuccess}
            onError={handleError}
            errorMessage={errorMessage}
          />
        </>
      )}
    </div>
  );
};

export default NewsletterFooter;
