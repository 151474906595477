export const ourTour = [
  {
    id: 1,
    whaleType: "humpback",
    price: 6995,
    from: false,
    swim: true,
    noOfNights: 8,
    hotel: true,
    liveAboard: false,
    internalFlights: true,
    country: "Tonga",
    location: "Tongatapu & Ha’apai",
    title: "Experience the Magic of Swimming with Humpback Whales in Tonga!",
    intro:
      "Join us for an unforgettable adventure as you swim with one of the ocean's most majestic creatures. Upon arrival in Tonga, we invite you to relax one night in Tongatapu before flying to the stunning island of U'oleva, known for its breathtaking beauty.",
    description:
      "Experience the tranquility of island life—no shops, no roads, just stunning beaches and the thrill of swimming with humpback whales. As part of a small exclusive group, we will be staying in a private and completely off-grid eco resort where nature and adventure collide.",
    inclusions: [
      "1 Night in Tongatapu before the adventure",
      "7 Nights in Beachfront Fales on U'oleva",
      "5 Days of Whale Swimming excursions",
      "Breakfast, lunch, and dinner from Day Two",
      "Access to kayaks, paddleboards, and snorkelling",
      "All transfers and return inter-island flights",
      "An escorted tour by our knowledgeable Awesome Whale Adventures team",
      "Off-grid introduction tour available on request",
    ],
    research: false,
    wifi: true,
    images: [
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/humpback-whales", // Hero image
      },

      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/relax-by-the-sea",
      },
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/cake-in-tonga",
      },
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/sunset",
      },
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/uoleva-aerial",
      },
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/humpback-calf-tonga",
      },
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/island-breakfast",
      },
      {
        url: "https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/curious-humpback-calf-tonga",
      },
    ],
    dates: "July 29 - August 6",
    limited: true,
    experience: `<h2>What to Expect on Your Whale Encounter with Awesome Whale Adventures</h2>
    <h3>An Unforgettable Experience</h3>
    <p>Imagine yourself surrounded by the crystal-clear waters of the South Pacific, immersed in an unforgettable encounter with the majestic humpback whales. Tonga offers one of the world’s most incredible whale experiences, and we invite you to join us for a once-in-a-lifetime opportunity to swim with and observe these gentle giants in their natural environment.</p>
    
   

    <h3>Ha'apai, Tonga – A Whale’s Paradise</h3>
    <p>
        Tonga is one of the few places on Earth where you can swim with humpback whales. These magnificent creatures migrate from Antarctica to the warm waters of Tonga’s islands, travelling over 6,000 km in one of the world’s largest animal migrations. From mid-July to October, you’ll witness their fascinating mating rituals, calf-rearing, and playful interactions in their natural habitat.
    </p>
    <p>
        Within Tonga, Ha'apai stands out as a truly special destination. This remote group of small islands offers an exclusive and tranquil whale-swimming experience. With only a limited number of licenses granted, it’s rare to see other boats on the water. This unmatched seclusion makes encounters with these gentle giants in Ha'apai an unforgettable and intimate experience.
    </p>
    

    
    <h3>What to Expect During Your Encounter</h3>
    <p>Each day on the water is a unique adventure! During your whale swim, you’ll have the opportunity to encounter humpback whales of all ages and see everything from curious calves to powerful adult males and nurturing mothers. Here’s a look at what you can expect:</p>
    <ul>
      <li><strong>Playful Calves & Energetic Males:</strong> The waters off Tonga are teeming with activity as young calves explore their surroundings, and adult males show off their strength in competitive displays.</li>
      <li><strong>Bonding with Nature:</strong> Observe intimate mother-calf interactions and witness the awe-inspiring grace of these creatures as they swim in close proximity, allowing you to create a bond with nature unlike anything else.</li>
      <li><strong>Crystal-clear Waters:</strong> Tonga’s pristine waters provide perfect visibility, so you can truly appreciate the beauty of the whales and their underwater world.</li>
    </ul>
    
    <h3>Respectful Whale Watching</h3>
    <p>At Awesome Whale Adventures, we believe in responsible tourism. We ensure that every interaction with the whales is conducted with their well-being in mind. This means:</p>
    <ul>
      <li><strong>No Pursuit:</strong> We never chase whales. We respect their natural movements and only swim with them if they willingly approach.</li>
      <li><strong>Keeping a Safe Distance:</strong> We maintain a respectful distance to allow the whales to feel safe and comfortable, ensuring a positive experience for both the creatures and our guests.</li>
      <li><strong>Environmental Consciousness:</strong> Every tour is designed with sustainability in mind, preserving Tonga’s marine environment for future generations to enjoy.</li>
    </ul>
    
    <h3>The Best Time to Visit</h3>
    <p>To maximise your chances of swimming with humpback whales, we recommend booking your adventure between mid-July and October, which is the peak whale season. This is when the whales gather in large numbers, and the waters are at their clearest.</p>
    
    <h3>Your Whale Swim Days</h3>
    <ul>
      <li><strong>Departing Early:</strong> Whale encounters typically begin in the morning around 8:30 am to 9 am to take advantage of the best weather conditions.</li>
      <li><strong>Guided Experiences:</strong> Our expert guides will brief you on safety protocols, whale behaviour, and how we interact with these gentle giants. You’ll swim with groups of four guests, ensuring a personalised experience.</li>
      <li><strong>Lunch and Exploration:</strong> We take breaks to explore Tonga’s stunning coral reefs and remote beaches, offering opportunities to snorkel and relax.</li>
      <li><strong>Return by Afternoon:</strong> After a full day of whale watching and swimming, we return to shore around 2:30pm with memories of an extraordinary adventure.</li>
    </ul>
    
    <h3>Comfortable, Safe, and Enjoyable</h3>
    <p>Our exclusive boat is designed with your comfort in mind, offering:</p>
    <ul>
      <li><strong>Easy Entry/Exit:</strong> For a smooth transition from boat to water.</li>
      <li><strong>Shade and Relaxation:</strong> Stay comfortable during your journey between encounters.</li>
      <li><strong>Hydrophone Technology:</strong> Listen to the beautiful whale songs above water!</li>
    </ul>
    
    <h3>Book Your Encounter Today</h3>
    <p>Don’t miss out on the adventure of a lifetime. Due to the exclusive nature of our tours, spaces fill up quickly, so we recommend booking in advance to secure your spot. Contact us today to reserve your place and get ready for an unforgettable experience swimming with whales in the heart of Tonga.</p>`,
  },
];
