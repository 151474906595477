import React, { forwardRef } from "react";
import { Social, Copyright, NewsletterFooter } from "../index";
import "./Footer.css";

const Footer = forwardRef((props, ref) => {
  return (
    <div id='connect' ref={ref}>
      <div className='footer-container'>
        <div className='footer-newsletter'>
          <NewsletterFooter />
        </div>

        <div className='footer-contact'>
          <h3>Contact Us</h3>
          <a
            className='email-link'
            href='mailto:hello@awesomewhaleadventures.com?subject=Website%20Enquiry'
            target='_blank'
            rel='noopener noreferrer'
          >
            hello@awesomewhaleadventures.com
          </a>
          <p>Phone: +61 485 933 782</p>

          <Social />
        </div>
      </div>

      <div className='footer-legal'>
        <Copyright />
        <p>
          <a href='/privacy-policy' rel='noopener noreferrer'>
            Privacy Policy |
          </a>
          <a href='/terms-and-conditions' rel='noopener noreferrer'>
            {" "}
            Terms & Conditions
          </a>
        </p>
      </div>
    </div>
  );
});

export default Footer;
