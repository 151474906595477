import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { Loading } from "../index";
import "./Blog.css";

const BlogIndex = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCount, setVisibleCount] = useState(4);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogCollection = collection(db, "blogs");
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map((doc) => ({
          id: doc.id,
          slug: doc.data().slug,
          timeStamp: doc.data().timeStamp,
          ...doc.data(),
        }));

        // Sort blogs by date (most recent first)
        const sortedBlogs = blogList.sort((a, b) => {
          const dateA = a.timeStamp?.seconds * 1000;
          const dateB = b.timeStamp?.seconds * 1000;
          return dateB - dateA; // Sort by most recent
        });

        setBlogs(sortedBlogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("An error occurred while loading the blog posts.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Get an excerpt from the content
  const getExcerpt = (content) => {
    if (!content) return "";
    const excerpt = content.replace(/(<([^>]+)>)/gi, "").slice(0, 150);
    return `${excerpt}...`;
  };

  // Handle Search Filtering
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredBlogs = blogs.filter(
    (post) =>
      post.title.toLowerCase().includes(searchQuery) ||
      (post.tags &&
        post.tags.some((tag) => tag.toLowerCase().includes(searchQuery)))
  );

  if (loading) return <Loading message='Loading blog posts, please wait...' />;
  if (error) return <p className='error-message'>{error}</p>;

  // Determine which posts should be visible
  const visiblePosts = filteredBlogs.slice(0, visibleCount);

  // Handle Load More Click
  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 2);
  };

  return (
    <div className='blog-preview-container'>
      <h1>Whale Adventures & Stories</h1>

      {/* Search Bar */}
      <div className='search-container'>
        <input
          type='text'
          className='search-bar'
          placeholder='Search blog posts...'
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      {/* Blog Cards */}
      <div className='blog-posts'>
        {filteredBlogs.length === 0 ? (
          <p>No blog posts found.</p>
        ) : (
          <div className='blog-cards-grid'>
            {visiblePosts.map((post) => (
              <div key={post.id} className='blog-card'>
                {post.heroImage && (
                  <div className='blog-card-image'>
                    <img
                      src={post.heroImage}
                      alt={post.title || ""}
                      className='blog-preview-image'
                    />
                  </div>
                )}
                <div className='blog-card-body'>
                  <h2>{post.title || "Untitled"}</h2>
                  <p className='blog-excerpt'>
                    {post.excerpt || getExcerpt(post.content)}
                  </p>
                  <Link to={`/blog/${post.slug}`} className='read-more'>
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Load More Button */}
      <div className='load-more-button'>
        <button
          onClick={handleLoadMore}
          disabled={visiblePosts.length >= filteredBlogs.length}
        >
          {visiblePosts.length >= filteredBlogs.length
            ? "No More Posts"
            : "Load More Blogs"}
        </button>
      </div>
    </div>
  );
};

export default BlogIndex;
