import React, { useState } from "react";
import "../Newsletter/Newsletter.css";
import NewsletterForm from "../Newsletter/NewsletterForm";
import { useResponsive } from "../../hooks";

const NewsletterPopUp = ({ onClose }) => {
  const [isSent, setIsSent] = useState(false);

  const { isMobile } = useResponsive();

  const handleSuccess = () => {
    setIsSent(true);
  };

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className='popup-overlay' onClick={handleClose}>
      <div className='popup-content'>
        <button className='close-btn' onClick={onClose}>
          ×
        </button>
        {isSent ? (
          <>
            <h2>Thank you for subscribing!</h2>
            <p>
              You're now part of our community! Get ready to receive the latest
              updates, exclusive offers, and exciting Awesome Whale Adventure
              news straight to your inbox. We can’t wait to share our adventures
              with you!
            </p>
          </>
        ) : (
          <>
            {isMobile ? (
              <h2>Join Our Exclusive Community</h2>
            ) : (
              <h2>Join Our Exclusive Whale Lovers Community</h2>
            )}
            <p>
              Subscribe now for exclusive deals, early notifications of tours,
              and exciting updates — be the first to know!
            </p>
            <NewsletterForm onSuccess={handleSuccess} />
          </>
        )}
      </div>
    </div>
  );
};

export default NewsletterPopUp;
