import React, { useState, useEffect } from "react";
import { SEO } from "../components";
import { useLocation } from "react-router-dom";

const ThankYouEnquiryPage = () => {
  const { state } = useLocation();
  // Set default tour info if state is available
  const [tourInfo, setTourInfo] = useState(state?.tour || {});

  useEffect(() => {
    if (state && state.tour) {
      setTourInfo(state.tour); // Ensure tourInfo is the tour object
    }
  }, [state]);

  return (
    <div className='pages-container'>
      <SEO
        title='Thank You for Your Enquiry | Awesome Whale Adventures'
        description='Your enquiry about our whale watching and swimming tours has been received. We will get back to you soon.'
        keywords='thank you, whale tours enquiry, booking confirmation, whale watching, swimming with whales, Awesome Whale Adventures, adventure travel'
      />

      <div className='thank-you-message'>
        <h1>Thank you for your enquiry!</h1>
        <h3>
          Thank you for your interest in our {tourInfo.whaleType} whale tour in{" "}
          {tourInfo.country}!
          <br />
          <br />
          Our team will be in touch within the next 24-48 hours to provide you
          with all the exciting details and help you prepare for the experience
          of a lifetime!
        </h3>
      </div>
    </div>
  );
};

export default ThankYouEnquiryPage;
