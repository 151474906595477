import React from "react";
import { BlogIndex, SEO } from "../components";
import "./Pages.css";

const Blog = () => {
  return (
    <>
      <SEO
        title='Whale Adventures Blog | Tips, Stories & Ethical Whale Tours'
        description='Explore the latest tips, stories, and insights from Awesome Whale Adventures'
        keywords='whale tours, ethical whale tours, whale watching, sustainable travel, whale adventures, tips and stories'
      />
      <div className='pages-container'>
        <BlogIndex />
      </div>
    </>
  );
};

export default Blog;
