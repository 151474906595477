import React from "react";
import { logoNew } from "../../assets";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../index";
import { useResponsive } from "../../hooks";
import "./Hero.css";

const Hero = () => {
  const navigate = useNavigate();

  const { isMobile, isDesktop } = useResponsive();

  const handleClick = () => {
    navigate("/all-tours");
  };

  return (
    <section className='hero'>
      <div className='hero-img-container'>
        <img
          src={
            !isDesktop
              ? `https://res.cloudinary.com/dinnupyay/image/upload/w_600,h_800,f_webp,q_auto/v1732613832/eliannedipp_mobile_zjgipf.jpg`
              : `https://res.cloudinary.com/dinnupyay/image/upload/w_1200,h_800,f_webp,q_auto/v1738757101/awa-whale-header.jpg`
          }
          alt='Whale Adventure Hero'
          className='hero-img'
        />
        <div className='hero-overlay'>
          <div className='hero-logo-container'>
            {isMobile && (
              <img
                src={logoNew}
                className='hero-logo-icon'
                alt='Awesome Whale Adventures logo'
              />
            )}
          </div>
          <div className='hero-content'>
            <h1>Unforgettable Whale Swim & Whale Watching Adventures</h1>
            <div className='hero-button-container'>
              <CustomButton
                text='Explore All Tours'
                onClick={handleClick}
                aria-label='Navigate to all tours page'
                title='Explore all available whale tours'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
