import React from "react";
import { BookingButton } from "../index";
import { useResponsive } from "../../hooks";
import "./FinalCTASection.css";

const FinalCTASection = () => {
  const { isMobile } = useResponsive();
  return (
    <section className='final-cta'>
      <h2>Don’t Miss This Once-in-a-Lifetime Opportunity</h2>
      <p>
        Spaces are limited, and the whales are waiting. Secure your spot today.
      </p>
      <div className='booking-link-container'>
        <BookingButton
          tourId={1}
          isBookingLink={true}
          text={isMobile ? "Book Now" : "Book Your Adventure Now"}
          alternateColor={true}
        />
      </div>
    </section>
  );
};

export default FinalCTASection;
