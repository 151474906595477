import React, { createContext, useState } from "react";
import { convertPrice } from "../utils/currencyUtils";

const CurrencyContext = createContext();

const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("AUD"); // Default currency AUD
  const [rates, setRates] = useState({ AUD: 1 }); // Default rates (AUD to AUD only)
  const [loading, setLoading] = useState(false);
  const [apiFailed, setApiFailed] = useState(false); // To track API failure

  // Function to fetch exchange rates dynamically
  const fetchExchangeRates = async () => {
    setLoading(true);
    try {
      const apiKey = process.env.REACT_APP_EXCHANGE_API_KEY;
      const response = await fetch(
        `https://v6.exchangerate-api.com/v6/${apiKey}/latest/AUD`
      );

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();
      setRates(data.conversion_rates);
      setApiFailed(false);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      setApiFailed(true); // Mark the API as failed
      setRates({ AUD: 1 }); // Reset to base rates (AUD only)
    }
    setLoading(false);
  };

  const handleCurrencyChange = (newCurrency) => {
    if (newCurrency === "AUD") {
      setCurrency("AUD");
      setRates({ AUD: 1 }); // Reset rates to AUD only
      setApiFailed(false); // Clear any API failure state
    } else {
      setCurrency(newCurrency);
      fetchExchangeRates(); // Fetch rates only when a different currency is selected
    }
  };

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency: handleCurrencyChange,
        rates,
        loading,
        apiFailed,
        convertPrice: (price) => convertPrice(price, rates, currency),
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export { CurrencyProvider, CurrencyContext };
