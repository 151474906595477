import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <section className='landing-page-hero'>
      <div className='hero-background'></div>
      <div className='hero-overlay'></div>
      <div className='landing-hero-content'>
        <h1>Swim with humpback whales in Tonga.</h1>
        <h2> 30 July - 6 August 2025</h2>
        <p>Exclusive, ethical adventure in the heart of the South Pacific.</p>
      </div>
      <div className='hero-landing-page-button'></div>
    </section>
  );
};

export default HeroSection;
