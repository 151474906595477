import React, { useState } from "react";
import { CustomButton, Modal } from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneArrival,
  faWater,
  faUsers,
  faUmbrellaBeach,
  faCameraRetro,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import "./AboutExperienceSection.css";
import { useResponsive } from "../../hooks";

const AboutExperienceSection = () => {
  const { isMobile } = useResponsive();
  const webhookURL = process.env.REACT_APP_MAKE_WEBHOOK_BROCHURE_URL;
  const stepsDesktop = [
    {
      title: "Arrival and Welcome",
      description:
        "Begin your journey with a warm welcome by your tour guides, ready for your unforgettable adventure.",
      icon: faPlaneArrival,
    },
    {
      title: "Awesome Whale Adventures",
      description:
        "Experience incredible whale encounters during guided swims over five days. Our expert team ensure safety and ethical interactions.",
      icon: faWater,
    },
    {
      title: "Peak Whale Season",
      description:
        "From July to October, witness the awe-inspiring migration of humpback whales in Tonga—this is the prime time for unforgettable whale encounters.",
      icon: faStar,
    },
    {
      title: "Crystal-Clear Waters",
      description:
        "Explore the stunning underwater world of Tonga, offering excellent visibility for your close-up whale encounters and aquatic adventures.",
      icon: faStar,
    },
    {
      title: "Whale Education Sessions",
      description:
        "Enhance your experience with engaging whale education sessions. Learn about whale behaviour, migration patterns, and ongoing conservation efforts to protect these incredible creatures.",
      icon: faStar,
    },
    {
      title: "Community and Connection",
      description:
        "Bond with like-minded travellers over shared meals and stories in this peaceful, tropical setting. The perfect space to connect, reflect, and build lasting friendships.",
      icon: faUsers,
    },
    {
      title: "Free Time to Explore",
      description:
        "Relax and recharge during your free time. Kayak, stand-up-paddle, snorkel, or simply sit back, unwind and soak in the breathtaking island views and enjoy the slower island pace of life.",
      icon: faUmbrellaBeach,
    },
    {
      title: "Departure with Memories",
      description:
        "Leave with a heart full of memories, new friends, and a deeper connection to the ocean and its magnificent creatures.",
      icon: faCameraRetro,
    },
  ];

  // Define the indices of steps you want to display on mobile
  const mobileStepIndices = [0, 1, 5, 7];

  const stepsMobile = mobileStepIndices.map((index) => stepsDesktop[index]);

  const [showModal, setShowModal] = useState(false);

  return (
    <section className='what-to-expect'>
      {isMobile ? (
        <h2>What to Expect</h2>
      ) : (
        <h2>What to Expect During Your Whale Adventure</h2>
      )}

      <p className='what-to-expect-intro'>
        Imagine swimming alongside humpback whales, guided by experts who
        prioritise your safety and the well-being of these majestic creatures.
      </p>

      {!isMobile && (
        <p>
          Our Tonga Whale Swim Tour offers a rare and unforgettable opportunity
          to connect with nature, leaving you inspired and refreshed.
        </p>
      )}

      <div className='steps-grid'>
        {(isMobile ? stepsMobile : stepsDesktop).map((step, index) => (
          <div key={index} className='step-card'>
            <div className='large-icon'>
              <FontAwesomeIcon icon={step.icon} />
            </div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>

      <div className='download-container' onClick={() => setShowModal(true)}>
        <CustomButton
          className='download-button'
          type='submit'
          text='Download Tour Brochure'
          marginTop={"20px"}
        />
      </div>

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        webhookURL={webhookURL}
      />
    </section>
  );
};

export default AboutExperienceSection;
