import React from "react";
import ReactPixel from "react-facebook-pixel";
import { useNavigate } from "react-router-dom";
import "./Button.css";

const BookingButton = ({ tourId, isBookingLink, text, alternateColor }) => {
  const navigate = useNavigate();

  const buttonStyles = {
    backgroundColor: alternateColor
      ? "var(--secondary-brand-color)"
      : "var(--primary-brand-color)",
    color: "var(--site-white)",
  };

  // Handle Enquire button click
  const handleEnquireClick = () => {
    // Facebook Pixel tracking
    ReactPixel.trackCustom("EnquireButtonClick", { tourId });

    // Navigate to booking enquiry with the tour ID
    navigate("/booking-enquiry", { state: { tourId } });
  };

  // Render as a booking link if isBookingLink is true
  if (isBookingLink) {
    return (
      <a
        href='https://awesomewhaleadventures.rezdy.com/687617/awesome-whale-adventures-whale-swim-tour-tonga-30-july-6-august-2025'
        target='_blank'
        rel='noopener noreferrer'
        className='custom-button link'
        style={{ ...buttonStyles }}
      >
        {text || "Book Now"}
      </a>
    );
  }

  // Otherwise, render as an enquiry button
  return (
    <button
      className='custom-button'
      onClick={handleEnquireClick}
      style={buttonStyles}
    >
      {text || "Enquire Now"}
    </button>
  );
};

export default BookingButton;
