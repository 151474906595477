import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faSwimmer,
  faLeaf,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import "./KeyFeaturesSection.css";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../index";
import { useResponsive } from "../../hooks";

const KeyFeaturesSection = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  return (
    <section className='key-features'>
      <h2>
        {isMobile
          ? `Why Choose Us?`
          : `Why Choose Our Tonga Whale Swim Adventure?`}
      </h2>
      <ul>
        <li>
          <FontAwesomeIcon icon={faUsers} className='large-icon' />
          <div>
            <strong>
              {isMobile
                ? `Small Group Experience`
                : `Small Group Experience – An Intimate & Unforgettable Adventure`}
            </strong>
            <p>
              {isMobile
                ? `With just 6-8 guests, you’ll enjoy more time in the water with the
              humpback whales.`
                : `With just 6-8 guests per tour, you'll enjoy an intimate and unhurried experience in the water with the majestic humpback whales of Ha'apai. Fewer participants mean more time in the water, personalised attention from expert guides, and a deeper connection with these gentle giants. Our small groups allow you to soak in every magical moment, whether it's watching a mother whale gently guide her calf or hearing the hauntingly beautiful whale songs beneath the waves.`}
            </p>
          </div>
        </li>
        <li>
          <FontAwesomeIcon icon={faSwimmer} />
          <div>
            <strong>
              {isMobile
                ? `Expert Guides`
                : `Expert Guides – Safety, Knowledge & Deep Respect`}
            </strong>
            <p>
              {isMobile
                ? `Certified whale guides prioritise your safety and share in-depth
              knowledge of these incredible creatures.`
                : `Our certified whale guides prioritise both your safety and the whales' well-being. With years of experience in whale behaviour and ethical swimming practices, they will guide you through every encounter, ensuring you feel comfortable and confident in the water. Learn fascinating insights about these migratory giants—from their breaching techniques to their deep emotional bonds—as our guides bring science, storytelling, and conservation together for an enriching experience.`}
            </p>
          </div>
        </li>
        <li>
          <FontAwesomeIcon icon={faHandsHelping} />
          <div>
            <strong>
              {isMobile
                ? `Ethical Tourism`
                : `Ethical Tourism – Responsible Encounters That Protect Whales`}
            </strong>
            <p>
              {isMobile
                ? `We adhere to responsible guidelines to ensure safe and respectful
              interactions with humpback whales.`
                : `We believe in responsible tourism that protects both the whales and their natural habitat. Our tours strictly follow ethical guidelines, including no-chase policies, maintaining a respectful distance, and allowing the whales to dictate the interaction. We strictly follow local guidelines to promote sustainable tourism practices that minimise human impact while maximising the opportunity for truly meaningful, once-in-a-lifetime encounters. By choosing this experience, you’re supporting marine conservation and responsible travel that ensures future generations can witness these incredible creatures in their natural environment.`}
            </p>
          </div>
        </li>
        <li>
          <FontAwesomeIcon icon={faLeaf} />
          <div>
            <strong>Idyllic Setting</strong>
            <p>
              {isMobile
                ? `Stay in eco-friendly, off-grid private island accommodations in
              Ha'apai surrounded by natural beauty.`
                : `Nestled in the pristine, untouched beauty of Ha'apai, our accommodations offer a true off-grid retreat where nature takes center stage. Stay in eco-friendly beachfront fales, where the sound of gentle waves replaces alarm clocks, and the crystal-clear waters are just steps from your door. This private island sanctuary is designed for sustainable comfort, blending seamlessly with the natural surroundings to provide a relaxing, rejuvenating escape. Disconnect from the noise of everyday life and reconnect with nature in this hidden paradise—the perfect setting for an unforgettable whale-swimming adventure.`}
            </p>
          </div>
        </li>
      </ul>
      <div className='button-container'>
        <CustomButton
          className='hero-btn'
          type='button'
          text={isMobile ? `Explore Inclusions` : `Discover Tour Inclusions`}
          onClick={() => navigate("/tours/1/tonga/humpback/swim-adventure")}
          marginBottom={true}
        />
      </div>
    </section>
  );
};

export default KeyFeaturesSection;
