import React from "react";
import { useResponsive } from "../../hooks";
import "./TestimonialSection.css";

const TestimonialsSection = () => {
  const { isMobile } = useResponsive();

  const testimonials = [
    {
      quote:
        "It has been such a smooth process booking with you, will absolutely let friends and family know.",
      author: "Somer, Australia",
    },
    {
      quote:
        "Thank you! I just wanted to highlight the amazing booking experience I had.",
      author: "May, New York",
    },
  ];

  return (
    <section className='testimonials'>
      <h2>
        {isMobile
          ? "Guests Love Booking With Us"
          : "What Our Guests Love About Booking With Awesome Whale Adventures"}
      </h2>
      <div className='testimonial-cards'>
        {testimonials.map((testimonial, index) => (
          <div key={index} className='testimonial-card'>
            <p className='quote'>"{testimonial.quote}"</p>
            <p className='author'>— {testimonial.author}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsSection;
